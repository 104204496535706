import React from 'react';

const Circle = ({ color = '#37B4A9' }: { color?: string }) => {
	return (
		<svg
			width='27'
			height='27'
			viewBox='0 0 27 27'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				opacity='0.5'
				d='M13.557 26.5963C10.1386 26.5963 6.86022 25.2386 4.44307 22.8218C2.02591 20.405 0.667969 17.127 0.667969 13.7092C0.667969 10.2914 2.02591 7.0136 4.44307 4.59683C6.86022 2.18005 10.1386 0.822266 13.557 0.822266C16.9753 0.822324 20.2536 2.18006 22.6707 4.59683C25.0878 7.0136 26.4457 10.2914 26.4457 13.7092C26.4458 15.4015 26.1124 17.0773 25.4647 18.6409C24.817 20.2044 23.8676 21.6251 22.6708 22.8218C21.4739 24.0185 20.0531 24.9676 18.4893 25.6153C16.9256 26.2629 15.2496 26.5963 13.557 26.5963Z'
				fill={color}
			/>
		</svg>
	);
};

export default Circle;
